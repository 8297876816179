var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-container",
    { staticClass: "container-fluid", attrs: { id: "options-slide" } },
    [
      _c("slide-heading", {
        attrs: { "html-heading": _vm.currentSlide.heading },
      }),
      _c("slide-image-and-description", { attrs: { slide: _vm.currentSlide } }),
      _vm.loaded
        ? _c(
            "div",
            { staticClass: "slide-options mt-5 mb-5" },
            _vm._l(_vm.currentSlide.options.options, function (option) {
              return _c("option-button", {
                key: option.value,
                attrs: { option: option },
                nativeOn: {
                  click: function ($event) {
                    return _vm.submitOption(option)
                  },
                },
              })
            }),
            1
          )
        : _c("ct-centered-spinner", [_vm._v("\n    Submitting...\n  ")]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }